import { Pipe, PipeTransform } from '@angular/core';

const sectionText = {
	['rent-residential']: 'Rent',
	['sell-residential']: 'Sale',
	['share-residential']: 'Share',
};

@Pipe({ name: 'sectionText' })
export class SectionTextPipe implements PipeTransform {
	/**
	 * Filters the list of objects based on the filter value and keys provided.
	 * We can refactor this if we want to filter the whole object without specifying a property using keys.
	 *
	 * @param reference section reference i.e rent-residential
	 * @returns List of objects filtered by filter value using keys
	 */
	transform(reference: string): any {
		return sectionText[reference];
	}
}
