import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[findqoDragDrop]',
})
export class DragDropDirective {
	@Output() fileDropped = new EventEmitter<any>();

	// Dragover listener
	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	// Dragleave listener
	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	// Drop listener
	@HostListener('drop', ['$event']) public ondrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const FILES = evt?.dataTransfer?.files;

		if (FILES.length > 0) {
			this.fileDropped.emit(FILES);
		}
	}
}
