import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfigState } from '@core/data-access/states/config.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import { FilterType } from '@core/enums';
import { Aisle, SortBy } from '@core/models';
import { Filter } from '@core/models/filters.model';
import { CommonUtil } from '@core/utils/common.util';
import { Observable, map } from 'rxjs';

@Pipe({
	name: 'quickFilters',
})
export class QuickFiltersPipe implements PipeTransform {
	/**
	 * Get the base filters
	 */
	transform(filterList: Filter[]): Filter[] {
		const IS_BEDROOM_KEY = filterList.some((flt) => flt.paramName === FilterType.BEDROOM_COUNT);
		const QUICK_FILTER_LIST = [FilterType.PROPERTY_TYPE, FilterType.PRICE, IS_BEDROOM_KEY ? FilterType.BEDROOM_COUNT : FilterType.PROPERTY_SIZES];

		const FILTERED_LIST = filterList.filter((filter: Filter) => QUICK_FILTER_LIST.includes(filter.paramName));

		const SORTED_LIST = FILTERED_LIST.sort(
			(filterA: Filter, filterB: Filter) => QUICK_FILTER_LIST.indexOf(filterA.paramName) - QUICK_FILTER_LIST.indexOf(filterB.paramName)
		);

		return SORTED_LIST;
	}
}

/**
 * Returns only County and County Area Filters
 */
@Pipe({
	name: 'countyAndAreaFilters',
})
export class CountyAndAreaFiltersPipe implements PipeTransform {
	/**
	 * Get the base filters
	 */
	transform(filterList: Filter[]): Filter[] {
		const FILTERED_LIST = filterList.filter((filter: Filter) => filter.paramName === FilterType.COUNTY || filter.paramName === FilterType.COUNTY_AREA);

		return FILTERED_LIST;
	}
}

@Pipe({
	name: 'commonFilters',
})
export class CommonFiltersPipe implements PipeTransform {
	/**
	 * Get the base filters
	 */

	transform(filterList: Filter[]): Filter[] {
		const IS_BEDROOM_KEY = filterList.some((flt) => flt.paramName === FilterType.BEDROOM_COUNT);
		const QUICK_FILTER_LIST = [FilterType.PROPERTY_TYPE, FilterType.PRICE, IS_BEDROOM_KEY ? FilterType.BEDROOM_COUNT : FilterType.PROPERTY_SIZES];

		const FILTERED_LIST = filterList?.filter(
			(filter: Filter) =>
				filter.paramName !== FilterType.COUNTY && filter.paramName !== FilterType.COUNTY_AREA && !QUICK_FILTER_LIST.some((flt) => flt === filter.paramName)
		);

		return FILTERED_LIST;
	}
}

@Pipe({
	name: 'toggleResetFilters',
})
export class ToggleResetFiltersPipe implements PipeTransform {
	constructor(private readonly route: ActivatedRoute) {}

	transform(): Observable<boolean> {
		return this.route.queryParams.pipe(map((queryParams: Params) => !CommonUtil.isEmptyObject(queryParams)));
	}
}

@Pipe({
	name: 'sortLabel',
})
export class SortLabelPipe implements PipeTransform {
	constructor(
		private readonly route: ActivatedRoute,
		private readonly configState: ConfigState,
		private readonly sectionState: SectionsState
	) {}

	transform(sortList: SortBy[]): Observable<string> {
		const TRANSLATIONS = this.configState.getTranslations();

		return this.route.queryParams.pipe(
			map((queryParams: Params) => {
				const PARAMS = this.route.snapshot.params;
				sortList = this.sectionState.getAisle(PARAMS?.[FilterType.SECTION], PARAMS?.[FilterType.AISLE])?.sorts || sortList;

				const SELECTED_SORT = sortList?.find((sort: SortBy) => sort.reference === queryParams[FilterType.SORT]);

				if (SELECTED_SORT) {
					return TRANSLATIONS['n2964'] + ` ${SELECTED_SORT.name}`;
				} else {
					return TRANSLATIONS['n2964'];
				}
			})
		);
	}
}

@Pipe({
	name: 'filtersHeadingLabel',
})
export class FiltersHeadingLabelPipe implements PipeTransform {
	constructor(private readonly route: ActivatedRoute) {}

	transform(selectedAisle: Aisle): Observable<string> {
		return this.route.queryParams.pipe(map(() => selectedAisle.name));
	}
}

@Pipe({
	name: 'disableFilter',
})
export class DisableFilterPipe implements PipeTransform {
	transform(filter: Filter): boolean {
		let isDisabled = false;

		if (filter.paramName === FilterType.COUNTY && !filter.values?.all.length) {
			isDisabled = true;
		} else if (filter.paramName !== FilterType.COUNTY && (!filter?.values || !filter.values?.length)) {
			isDisabled = true;
		}

		return isDisabled;
	}
}

/**
 * Get Aisle by reference key
 */
@Pipe({
	name: 'getAisle',
})
export class GetAislePipe implements PipeTransform {
	transform(aisleList: Aisle[], selectedAisle: string): Aisle {
		return aisleList?.find((aisle: Aisle) => aisle.reference === selectedAisle);
	}
}

/**
 * Get selected filters value
 */
@Pipe({
	name: 'getFilterValue',
	pure: false,
})
export class GetFilterValuePipe implements PipeTransform {
	constructor(private readonly configState: ConfigState) {}
	transform(fc: any, filter: Filter): string {
		let value = fc[filter?.paramName]?.value;

		switch (filter?.paramName) {
			case FilterType.BEDROOM_COUNT:
				value = value
					?.map((v) => (+v.id === 0 ? this.configState.getTranslations()['n3422'] : +v.id))
					.sort((a, b) => {
						if (typeof b === 'string') return 1; // If `b` is a string, `a` should come after `b`
						if (typeof a === 'string') return -1; // If `a` is a string, it should come first
						return a - b;
					})
					.join(', ');
				break;

			case FilterType.PRICE:
				value = value
					? value
							?.split(',')
							?.map((val) => `€${val}`)
							?.join(' - ')
					: null;
				break;
			default:
		}

		return value ? value : filter?.label;
	}
}
