import { Pipe, PipeTransform } from '@angular/core';
import { HEADER_ACCOUNT_NAV_LIST } from '@core/components/header/header.routes';
import { AuthService } from '@core/data-access/services/auth.service';
import { ClientUser } from '@core/models';

@Pipe({
	name: 'navMenuItems',
})
export class NavMenuItemsPipe implements PipeTransform {
	constructor(private readonly auth: AuthService) {}
	localUser = this.auth.user();

	transform(user: ClientUser, isSideNav: boolean, isAgentHub: boolean = false) {
		const UPDATED_DETAILS = { ...this.localUser, ...user };
		return HEADER_ACCOUNT_NAV_LIST(UPDATED_DETAILS, isSideNav, isAgentHub);
	}
}
