import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments/environment';

@Pipe({
	name: 'avatarImagePipe',
})
export class AvatarImagePipe implements PipeTransform {
	transform(image: string): string {
		return !!image ? image : environment.defaultAvatarImg;
	}
}
