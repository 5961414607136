import { Pipe, PipeTransform } from '@angular/core';
import { BER_EXEMPT } from '@core/constants';

@Pipe({
	name: 'adBerColor',
})
export class AdBerColorPipe implements PipeTransform {
	berVariants = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

	transform(ber: string) {
		if (ber === BER_EXEMPT || !ber) {
			return '';
		} else {
			return 'ber-' + this.berVariants.find((variant) => ber?.includes(variant)).toLowerCase();
		}
	}
}
