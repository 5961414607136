import { Pipe, PipeTransform } from '@angular/core';
import { AdPaymentStatus, AdStatus, SellerType } from '@core/enums';
import { EditAdResponseData } from '@core/models';

//to update the css of place ad plan page based on user has to make a payment or not
@Pipe({
	name: 'adPaid',
})
export class AdPaidPipe implements PipeTransform {
	constructor() {}

	transform(editAdData: EditAdResponseData, currentSellerId: string, isCouponApplied, isSeller = true): boolean {
		return isSeller
			? (editAdData?.adPayment?.status === AdPaymentStatus.PAID && editAdData?.adStatus?.status !== AdStatus.SOLD) ||
					currentSellerId === SellerType.BUSINESS ||
					isCouponApplied
			: editAdData?.adPayment?.status === AdPaymentStatus.PAID && editAdData?.adStatus?.status !== AdStatus.SOLD;
	}
}
