import { Pipe, PipeTransform } from '@angular/core';
import { Tenant } from '@core/models';

//To filter contacted tenant list

@Pipe({
	name: 'tenantContactedList',
})
export class TenantContactedListPipe implements PipeTransform {
	transform(value: Tenant[], isContacted: boolean): Tenant[] {
		if (!isContacted) {
			return value;
		} else {
			return value.filter((tenant) => tenant?.viewAppointment);
		}
	}
}
