import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'adTransform',
})
export class AdTransfromPipe implements PipeTransform {
	constructor() {}

	transform(value: string, property: string, yes: string, no: string, privateType?: string, business?: string): string {
		switch (property) {
			case 'isWarranty':
			case 'isOwnerOccupied':
			case 'isUnderConstruction':
				value = value === '0' ? no : yes;
				break;

			case 'propertySize':
				value = value?.['value'] + ' ' + value?.['parameter'];
				break;

			case 'seller':
				value = value?.['sellerTypeId'] === '1' ? privateType : business;
				break;

			case 'availableFrom':
				const date = new Date(+value * 1000);
				value = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
				break;

			case 'taxDesignation':
				value = value?.['name'];
				break;

			default:
				value = value;
				break;
		}

		return value;
	}
}
