/**
 * Add here all constant related to filters
 *
 * TODO: Add more details or create README.md file
 */

import { AisleType, FilterTemplateType, FilterType, FilterValueListEndpoint } from '@core/enums';
import { Filter } from '@core/models/filters.model';

const MOTORS_STAND_SHELF_FILTER = [
	{
		label: 'Category',
		paramName: FilterType.STAND,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		childReferenceParamName: FilterType.SHELF,
		valuesEndpoint: FilterValueListEndpoint.STAND,
		requestParamName: 'aisle',
		isMinMax: false,
	},
	{
		label: 'Type',
		paramName: FilterType.SHELF,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		parentReferenceParamName: FilterType.STAND,
		valuesEndpoint: FilterValueListEndpoint.SHELF,
		requestParamName: 'stand',
		isMinMax: false,
	},
];

const PROPERTY_TYPE = {
	label: 'Property Type',
	paramName: FilterType.PROPERTY_TYPE,
	templateType: FilterTemplateType.SINGLE_SELECTION,
	valuesEndpoint: FilterValueListEndpoint.STAND,
	requestParamName: 'aisle',
	isMinMax: false,
} as Filter;

// Ads filters constant
export const ADS_FILTERS = [
	{
		label: 'Price',
		paramName: FilterType.PRICE,
		templateType: FilterTemplateType.MIN_MAX_DROPDOWN,
		valuesEndpoint: FilterValueListEndpoint.PRICE,
	},
	{
		label: 'Seller',
		paramName: FilterType.SELLER_TYPE,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.SELLER_TYPE,
	},
	{
		label: 'Date Posted',
		paramName: FilterType.DATE,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.DATE,
	},

	// TBD
	{
		label: 'Location',
		paramName: '',
	},
	// TBD
	{
		label: 'Location Radius',
		paramName: '',
	},
	{
		label: 'Make', // Manufacturer
		placeholder: 'All Make',
		templateType: FilterTemplateType.SINGLE_SELECTION,
		paramName: FilterType.MANUFACTURER,
		childReferenceParamName: FilterType.PRODUCT,
		valuesEndpoint: FilterValueListEndpoint.MANUFACTURER,
	},
	{
		label: 'Model', // Product
		placeholder: 'All Model',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.PRODUCT,
		parentReferenceParamName: FilterType.MANUFACTURER,
		requestParamName: FilterType.MANUFACTURER,
		valuesEndpoint: FilterValueListEndpoint.PRODUCT,
		disabled: true,
	},
	{
		label: 'Trim',
		// placeholder: 'All trims',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.TRIM,
		valuesEndpoint: FilterValueListEndpoint.TRIM,
	},
	{
		label: 'Body',
		// placeholder: 'All body types',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.BODY_TYPE,
		valuesEndpoint: FilterValueListEndpoint.BODY_TYPE,
	},
	{
		label: 'Regional Spec',
		// placeholder: 'All regional specs',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.REGIONAL_SPEC,
		valuesEndpoint: FilterValueListEndpoint.REGIONAL_SPEC,
	},
	{
		label: 'Year',
		paramName: FilterType.YEAR,
		templateType: FilterTemplateType.MIN_MAX_DROPDOWN,
		valuesEndpoint: FilterValueListEndpoint.YEAR,
	},
	{
		label: 'Mileage',
		paramName: FilterType.MILEAGE,
	},
	{
		label: 'Door Count',
		paramName: FilterType.DOOR_COUNT,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.DOOR_COUNT,
	},
	{
		label: 'Fuel',
		// placeholder: 'All fuel types',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.FUEL_TYPE,
		valuesEndpoint: FilterValueListEndpoint.FUEL_TYPE,
	},
	{
		label: 'Engine Size (cars/litres)',
		paramName: 'filterEngineSizeLitres',
	},
	{
		label: 'Engine Size (bikes/cc)',
		paramName: FilterType.ENGINE_SIZE_CC,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.ENGINE_SIZE_CC,
	},
	{
		label: 'Color',
		// placeholder: 'All colors',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.COLOR,
		valuesEndpoint: FilterValueListEndpoint.COLOR,
	},
	{
		label: 'Body Condition',
		// placeholder: 'All body conditions',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.BODY_CONDITION,
		valuesEndpoint: FilterValueListEndpoint.BODY_CONDITION,
	},
	{
		label: 'Mechanical Condition',
		// placeholder: 'All mechanical conditions',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.MECHANICAL_CONDITION,
		valuesEndpoint: FilterValueListEndpoint.MECHANICAL_CONDITION,
	},
	{
		label: 'Condition',
		// placeholder: 'All conditions',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.CONDITION,
		valuesEndpoint: FilterValueListEndpoint.BODY_CONDITION,
	},
	{
		label: 'Warranty',
		templateType: FilterTemplateType.BOOLEAN_SELECTION,
		paramName: FilterType.IS_WARRANTY,
		values: [
			{
				id: '0',
				name: 'No',
			},
			{
				id: '1',
				name: 'Yes',
			},
		],
	},
	{
		label: 'No. of Cylinders',
		templateType: FilterTemplateType.MIN_MAX_DROPDOWN,
		paramName: FilterType.CYLINDER_COUNT,
		valuesEndpoint: FilterValueListEndpoint.CYLINDER_COUNT,
	},
	{
		label: 'Transmission',
		// placeholder: 'All transmissions',
		templateType: FilterTemplateType.MULTI_SELECTION,
		paramName: FilterType.TRANSMMISION,
		valuesEndpoint: FilterValueListEndpoint.TRANSMMISION,
	},
	{
		label: 'Horse Power',
		paramName: FilterType.HORSE_POWER,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.HORSE_POWER,
	},
	{
		label: 'Sterring Side',
		paramName: FilterType.STEERING_SIDE,
		templateType: FilterTemplateType.BOOLEAN_SELECTION,
		values: [
			{
				id: '0',
				name: 'Left',
			},
			{
				id: '1',
				name: 'Right',
			},
		],
	},
	{
		label: 'Vehicle Extras',
		// placeholder: 'All vehicle extras',
		paramName: FilterType.VEHICLE_EXTRAS,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.VEHICLE_EXTRAS,
	},
	{
		label: 'Number Plates',
		// placeholder: 'All Plate Digit Count',
		paramName: FilterType.PLATE_DIGIT_COUNT,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.PLATE_DIGIT_COUNT,
	},
	{
		label: 'Wheel Count',
		paramName: FilterType.WHEEL_COUNT,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.WHEEL_COUNT,
	},
	{
		label: 'Final Drive System',
		// placeholder: 'All final drive systems',
		paramName: FilterType.FINAL_DRIVE_SYSTEM,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.FINAL_DRIVE_SYSTEM,
	},
	{
		label: 'Usage',
		// placeholder: 'All usages',
		paramName: FilterType.USAGE,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.USAGE,
	},
	{
		label: 'Age',
		paramName: 'filterAgeMonths',
	},
	{
		label: 'Length',
		paramName: 'filterLengthFeet',
	},
	{
		label: 'Bedrooms',
		paramName: FilterType.BEDROOM_COUNT,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.BEDROOM_COUNT,
	},
	{
		label: 'Bathrooms',
		paramName: FilterType.BATHROOM_COUNT,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.BATHROOM_COUNT,
	},
	{
		label: 'Furnishing',
		paramName: FilterType.FURNISHING,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.FURNISHING,
	},
	{
		label: 'Size (Square Meters)',
		paramName: FilterType.SIZE_SQUARE_METERS,
		templateType: FilterTemplateType.MIN_MAX_INPUT,
	},
	{
		label: 'Amenities',
		paramName: FilterType.PROPERTY_EXTRAS,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.PROPERTY_EXTRAS,
	},
	{
		label: 'Size (Square Metres)',
		paramName: FilterType.PROPERTY_SIZES,
		templateType: FilterTemplateType.MIN_MAX_DROPDOWN,
		valuesEndpoint: FilterValueListEndpoint.PORPERTY_SIZES,
	},
	{
		label: 'Rental Period',
		paramName: FilterType.RENTAL_PERIOD,
		templateType: FilterTemplateType.MULTI_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.RENTAL_PERIOD,
	},
	{
		label: 'Construction Status',
		paramName: FilterType.IS_UNDER_CONSTRUCTION,
		templateType: FilterTemplateType.BOOLEAN_SELECTION,
		values: [
			{
				id: '0',
				name: 'No',
			},
			{
				id: '1',
				name: 'Yes',
			},
		],
	},
	{
		label: 'Room Type',
		paramName: FilterType.ROOM_TYPE,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.ROOM_TYPES,
	},
	{
		label: 'Access',
		paramName: FilterType.PARKING_ACCESS,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.PARKING_ACCESS,
	},
	{
		label: 'Preference',
		paramName: FilterType.TENANT_PREFERENCE,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.TENANT_PREFERENCE,
	},
	{
		label: 'Other Tenants Sharing',
		paramName: FilterType.OTHER_TENANT_SHARE_COUNT,
		templateType: FilterTemplateType.SINGLE_SELECTION,
		valuesEndpoint: FilterValueListEndpoint.OTHER_TENANT_SHARE_COUNT,
	},
	{
		label: 'Owner Occupied',
		paramName: FilterType.IS_OWNER_OCCUPIED,
		templateType: FilterTemplateType.BOOLEAN_SELECTION,
		values: [
			{
				id: '1',
				name: 'Yes',
			},
			{
				id: '0',
				name: 'No',
			},
		],
	},
	{
		label: '+1 Person Allowed',
		paramName: FilterType.IS_ONE_PLUS_PERSON_ALLOWED,
		templateType: FilterTemplateType.BOOLEAN_SELECTION,
		values: [
			{
				id: '1',
				name: 'Yes',
			},
			{
				id: '0',
				name: 'No',
			},
		],
	},
	{
		label: 'Number of spaces available',
		paramName: FilterType.PARKING_AVAILABLE_SPACE_COUNT,
		templateType: FilterTemplateType.MIN_MAX_INPUT,
	},
	{
		label: 'CPU',
		paramName: 'filterCpuId',
		valuesEndpoint: 'cpus',
		placeholder: 'All cpus',
	},
	{
		label: 'Screen Size',
		paramName: 'filterScreenSizeInches',
	},
	{
		label: 'Memory (RAM)',
		paramName: 'filterRamId',
		valuesEndpoint: 'rams',
		placeholder: 'All rams',
	},
	{
		label: 'Hard Drive/Storage Capacity',
		paramName: 'filterHardDriveId',
		valuesEndpoint: 'hard-drives',
		placeholder: 'All hard drives',
	},
	{
		label: 'CPU Speed (GHz)',
		paramName: 'filterCpuSpeedGhz',
	},
	{
		label: 'Battery Health',
		paramName: 'filterBatteryHealthId',
		valuesEndpoint: 'battery-healths',
		placeholder: 'All battery healths',
	},
	{
		label: 'Country of Origin',
		paramName: 'filterOriginCountryId',
		valuesEndpoint: 'country-origins',
		placeholder: 'All country of origins',
	},
	{
		label: 'Accompaniments (TBD)',
		paramName: 'filterElectronicExtras',
		valuesEndpoint: 'electronic-extras',
		placeholder: 'All electronic extras',
	},
	{
		label: 'Carrier Lock',
		paramName: 'filterIsCarrierLock',
	},
	{
		label: 'Experience',
		paramName: 'filterExperienceYearCount',
	},
	{
		label: 'Qualification',
		paramName: 'filterQualificationId',
		valuesEndpoint: 'qualifications',
		placeholder: 'All qualifications',
	},
	{
		label: 'Employment Type',
		paramName: 'filterEmploymentTypeId',
		valuesEndpoint: 'employment-types',
		placeholder: 'All employment types',
	},
	{
		label: 'Remote',
		paramName: 'filterIsRemoteWork',
	},
	{
		label: 'Kilometers',
		paramName: FilterType.KILOMETER,
		templateType: FilterTemplateType.MIN_MAX_DROPDOWN,
		valuesEndpoint: FilterValueListEndpoint.KILOMETER,
	},
] as Filter[];

// Orders of filters
export const ADS_FILTER_ORDERS = [
	// Included on Ireland version (V2)
	{
		aisle: AisleType.RENT_RESIDENTIAL,
		orders: [
			FilterType.PROPERTY_TYPE,
			FilterType.BEDROOM_COUNT,
			FilterType.FURNISHING,
			FilterType.PARKING_ACCESS,
			FilterType.PRICE,
			FilterType.PROPERTY_SIZES,
			FilterType.ROOM_TYPE,
			FilterType.PROPERTY_EXTRAS,
			FilterType.BATHROOM_COUNT,
		],
	},

	// Included on Ireland version (V2)
	{
		aisle: AisleType.SHARE_RESIDENTIAL,
		orders: [
			FilterType.ROOM_TYPE,
			FilterType.PROPERTY_TYPE,
			FilterType.PRICE,
			FilterType.OTHER_TENANT_SHARE_COUNT,
			FilterType.IS_OWNER_OCCUPIED,
			FilterType.IS_ONE_PLUS_PERSON_ALLOWED,
			FilterType.TENANT_PREFERENCE,
			FilterType.FURNISHING,
		],
	},

	// Included on Ireland version (V2)
	{
		aisle: AisleType.RENT_COMMERCIAL,
		orders: [
			FilterType.PROPERTY_TYPE,
			FilterType.FURNISHING,
			FilterType.PRICE,
			FilterType.PROPERTY_SIZES,
			FilterType.PROPERTY_EXTRAS,
			FilterType.BATHROOM_COUNT,
			FilterType.RENTAL_PERIOD,
			// FilterType.SELLER_TYPE,
		],
	},

	{
		aisle: AisleType.SALE_RESIDENTIAL,
		orders: [
			FilterType.PROPERTY_TYPE,
			FilterType.PRICE,
			FilterType.PROPERTY_SIZES,
			FilterType.ROOM_TYPE,
			FilterType.PROPERTY_EXTRAS,
			FilterType.BATHROOM_COUNT,
		],
	},

	{
		aisle: AisleType.SALE_COMMERCIAL,
		orders: [FilterType.PROPERTY_TYPE, FilterType.PRICE, FilterType.PROPERTY_SIZES, FilterType.PROPERTY_EXTRAS],
	},

	// {
	// 	aisle: 'cars',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.MANUFACTURER,
	// 		FilterType.PRODUCT,
	// 		FilterType.PRICE,
	// 		FilterType.YEAR,
	// 		FilterType.KILOMETER,
	// 		FilterType.BODY_TYPE,
	// 		FilterType.FUEL_TYPE,
	// 		FilterType.TRANSMMISION,
	// 		FilterType.REGIONAL_SPEC,
	// 		FilterType.CYLINDER_COUNT,
	// 		FilterType.HORSE_POWER,
	// 		FilterType.TRIM,
	// 		FilterType.COLOR,
	// 		FilterType.DOOR_COUNT,
	// 		FilterType.IS_WARRANTY,
	// 		FilterType.STEERING_SIDE,
	// 		FilterType.BODY_CONDITION,
	// 		FilterType.MECHANICAL_CONDITION,
	// 		FilterType.VEHICLE_EXTRAS,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: 'motorcycles',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.MANUFACTURER,
	// 		FilterType.STAND,
	// 		FilterType.SHELF,
	// 		FilterType.PRICE,
	// 		FilterType.KILOMETER,
	// 		FilterType.ENGINE_SIZE_CC,
	// 		FilterType.COLOR,
	// 		FilterType.FINAL_DRIVE_SYSTEM,
	// 		FilterType.IS_WARRANTY,
	// 		FilterType.WHEEL_COUNT,
	// 		FilterType.USAGE,
	// 		FilterType.BODY_CONDITION,
	// 		FilterType.MECHANICAL_CONDITION,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: 'heavy-vehicles',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.MANUFACTURER,
	// 		FilterType.STAND,
	// 		FilterType.SHELF,
	// 		FilterType.PRICE,
	// 		FilterType.YEAR,
	// 		FilterType.KILOMETER,
	// 		FilterType.BODY_TYPE,
	// 		FilterType.FUEL_TYPE,
	// 		FilterType.TRANSMMISION,
	// 		FilterType.REGIONAL_SPEC,
	// 		FilterType.CYLINDER_COUNT,
	// 		FilterType.HORSE_POWER,
	// 		FilterType.COLOR,
	// 		FilterType.DOOR_COUNT,
	// 		FilterType.IS_WARRANTY,
	// 		FilterType.STEERING_SIDE,
	// 		FilterType.BODY_CONDITION,
	// 		FilterType.MECHANICAL_CONDITION,
	// 		FilterType.VEHICLE_EXTRAS,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: 'number-plates',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.PRICE,
	// 		FilterType.DATE,
	// 		FilterType.PLATE_DIGIT_COUNT,
	// 		// FilterType.SELLER_TYPE,
	// 	],
	// },
	// {
	// 	aisle: 'accessories-parts',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.PRICE,
	// 		FilterType.STAND,
	// 		FilterType.SHELF,
	// 		FilterType.CONDITION,
	// 		FilterType.IS_WARRANTY,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: 'taxi',
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.DATE,
	// 		FilterType.PRICE,
	// 		// FilterType.SELLER_TYPE,
	// 	],
	// },
	// {
	// 	aisle: AisleType.BUY_RESIDENTIAL,
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.PROPERTY_TYPE,
	// 		FilterType.PRICE,
	// 		FilterType.BEDROOM_COUNT,
	// 		FilterType.BATHROOM_COUNT,
	// 		FilterType.FURNISHING,
	// 		FilterType.IS_UNDER_CONSTRUCTION,
	// 		FilterType.SIZE_SQUARE_METERS,
	// 		FilterType.PROPERTY_EXTRAS,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: AisleType.BUY_COMMERICAL,
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.PROPERTY_TYPE,
	// 		FilterType.PRICE,
	// 		FilterType.BATHROOM_COUNT,
	// 		FilterType.FURNISHING,
	// 		FilterType.IS_UNDER_CONSTRUCTION,
	// 		FilterType.SIZE_SQUARE_METERS,
	// 		FilterType.PROPERTY_EXTRAS,
	// 		FilterType.RENTAL_PERIOD,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
	// {
	// 	aisle: AisleType.NEW_PROJECTS,
	// 	orders: [
	// 		FilterType.COUNTY,
	// 		FilterType.PROPERTY_TYPE,
	// 		FilterType.PRICE,
	// 		FilterType.BEDROOM_COUNT,
	// 		FilterType.BATHROOM_COUNT,
	// 		FilterType.FURNISHING,
	// 		FilterType.IS_UNDER_CONSTRUCTION,
	// 		FilterType.SIZE_SQUARE_METERS,
	// 		FilterType.PROPERTY_EXTRAS,
	// 		// FilterType.SELLER_TYPE,
	// 		FilterType.DATE,
	// 	],
	// },
];

// Not included in API response
export const ADS_OTHER_FILTERS = [
	{
		aisle: AisleType.RENT_RESIDENTIAL,
		filters: [PROPERTY_TYPE],
	},
	{
		aisle: AisleType.RENT_COMMERCIAL,
		filters: [PROPERTY_TYPE],
	},
	{
		aisle: AisleType.SHARE_RESIDENTIAL,
		filters: [PROPERTY_TYPE],
	},
	{
		aisle: AisleType.SALE_RESIDENTIAL,
		filters: [PROPERTY_TYPE],
	},
	{
		aisle: AisleType.SALE_COMMERCIAL,
		filters: [PROPERTY_TYPE],
	},
	// {
	// 	aisle: 'motorcycles',
	// 	filters: [...MOTORS_STAND_SHELF_FILTER],
	// },
	// {
	// 	aisle: 'heavy-vehicles',
	// 	filters: [...MOTORS_STAND_SHELF_FILTER],
	// },
	// {
	// 	aisle: 'accessories-parts',
	// 	filters: [...MOTORS_STAND_SHELF_FILTER],
	// },
	// {
	// 	aisle: 'number-plates',
	// 	filters: [
	// 		{
	// 			label: 'Category',
	// 			// placeholder: 'All Categories',
	// 			paramName: FilterType.STAND,
	// 			templateType: FilterTemplateType.SINGLE_SELECTION,
	// 			childReferenceParamName: FilterType.SHELF,
	// 			valuesEndpoint: FilterValueListEndpoint.STAND,
	// 			requestParamName: 'aisle',
	// 			isMinMax: false,
	// 		},
	// 		{
	// 			label: 'Vehicle Type',
	// 			// placeholder: 'All Types',
	// 			paramName: FilterType.SHELF,
	// 			templateType: FilterTemplateType.SINGLE_SELECTION,
	// 			parentReferenceParamName: FilterType.STAND,
	// 			valuesEndpoint: FilterValueListEndpoint.SHELF,
	// 			requestParamName: 'stand',
	// 			isMinMax: false,
	// 		},
	// 	],
	// },
	// {
	// 	aisle: AisleType.BUY_RESIDENTIAL,
	// 	filters: [PROPERTY_TYPE],
	// },
	// {
	// 	aisle: AisleType.BUY_COMMERICAL,
	// 	filters: [PROPERTY_TYPE],
	// },
	// {
	// 	aisle: AisleType.NEW_PROJECTS,
	// 	filters: [PROPERTY_TYPE],
	// },
];

export const FILTERS_KEY_REFERENCED = [FilterType.SHELF, FilterType.STAND];
