import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
	AdElementVisibleDirective,
	AdImagePreloadDirective,
	AnchorDirective,
	AvatarImagePreloadDirective,
	DigitOnlyDirective,
	DragDropDirective,
	ExternalLinkDirective,
	FeatureIfDirective,
	IconWithLinkDirective,
	IntersectionObserverDirective,
} from './directives';

import {
	AdBerColorPipe,
	AdDetailRoutePipe,
	AdDetailTitlePipe,
	AdDetailsSlicePipe,
	AdImagePipe,
	AdListPageHeadingPipe,
	AdMainPhotoPipe,
	AdPaidPipe,
	AdPhotoIndexPipe,
	AdPricePipe,
	AdTransfromPipe,
	AisleFormatPipe,
	AvatarImagePipe,
	CommonFiltersPipe,
	CountryVersionNamePipe,
	CountyAndAreaFiltersPipe,
	DetailPropertyPipe,
	DisableFilterPipe,
	FilterLimitToPipe,
	FilterStatusPipe,
	FiltersAislePipe,
	FiltersHeadingLabelPipe,
	FormErrorMessagePipe,
	GetAislePipe,
	LinkifyPipe,
	MaskEmailPipe,
	MaskMobilePipe,
	NumberTrimPipe,
	PlaceADextrasSlicePipe,
	PriceSuffixPipe,
	QuickFiltersPipe,
	SearchFilterPipe,
	SelectAllFilterDropdownMultiOptionPipe,
	SelectFilterDropdownMultiOptionPipe,
	SelectFilterDropdownSingleOptionPipe,
	SelectedFilterOptionPipe,
	SortLabelPipe,
	TenantContactedListPipe,
	TenantOtherInfoPipe,
	TenantQualityPipe,
	TenantSummaryTimeSlotPipe,
	TenantTimeSlotsPipe,
	ToLocaleUpperCase,
	ToggleResetFiltersPipe,
	TranslationsPipe,
	VisiblePipe,
	CountyLinkPipe,
	TimeSlotsNamePipe,
	GetFilterValuePipe,
	TimeZoneNamePipe,
	TimeZoneTimePipe,
	TimeIntervalNamePipe,
	NavMenuItemsPipe,
	ExpiringInDays,
	AdStatusFormatPipe,
} from './pipes';
import { StandFormatPipe } from './pipes/place-ad/stand-format.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { SectionTextPipe } from './pipes/section.pipe';

const MODULES = [CommonModule, MatButtonModule, MatIconModule, MatNativeDateModule, RouterModule, NgOptimizedImage];

const PIPES = [
	AdDetailTitlePipe,
	AdDetailsSlicePipe,
	AdImagePipe,
	FilterLimitToPipe,
	FiltersAislePipe,
	FiltersHeadingLabelPipe,
	FormErrorMessagePipe,
	MaskEmailPipe,
	MaskMobilePipe,
	QuickFiltersPipe,
	SearchFilterPipe,
	SelectAllFilterDropdownMultiOptionPipe,
	SelectFilterDropdownMultiOptionPipe,
	SelectFilterDropdownSingleOptionPipe,
	SelectedFilterOptionPipe,
	SortLabelPipe,
	FiltersHeadingLabelPipe,
	AdImagePipe,
	AdTransfromPipe,
	ToLocaleUpperCase,
	ToggleResetFiltersPipe,
	TranslationsPipe,
	VisiblePipe,
	PlaceADextrasSlicePipe,
	DetailPropertyPipe,
	CountryVersionNamePipe,
	NumberTrimPipe,
	CountyAndAreaFiltersPipe,
	CommonFiltersPipe,
	FilterStatusPipe,
	DisableFilterPipe,
	AdDetailRoutePipe,
	AdListPageHeadingPipe,
	LinkifyPipe,
	GetAislePipe,
	AdPricePipe,
	AvatarImagePipe,
	AdPaidPipe,
	AdBerColorPipe,
	AisleFormatPipe,
	StandFormatPipe,
	PriceSuffixPipe,
	TenantOtherInfoPipe,
	TenantQualityPipe,
	TenantTimeSlotsPipe,
	TenantSummaryTimeSlotPipe,
	TenantContactedListPipe,
	AdMainPhotoPipe,
	AdPhotoIndexPipe,
	CountyLinkPipe,
	TimeSlotsNamePipe,
	GetFilterValuePipe,
	TimeZoneNamePipe,
	TimeZoneTimePipe,
	TimeIntervalNamePipe,
	NavMenuItemsPipe,
	ExpiringInDays,
	AdStatusFormatPipe,
	SectionTextPipe,
];

const DIRECTIVES = [
	DigitOnlyDirective,
	ExternalLinkDirective,
	AdElementVisibleDirective,
	FeatureIfDirective,
	DragDropDirective,
	AdImagePreloadDirective,
	IntersectionObserverDirective,
	AvatarImagePreloadDirective,
	IconWithLinkDirective,
	AnchorDirective,
];

@NgModule({
	declarations: [...PIPES, ...DIRECTIVES],
	imports: [...MODULES],
	exports: [...MODULES, ...PIPES, ...DIRECTIVES],
})
export class SharedModule {}
