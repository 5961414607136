import { Injectable } from '@angular/core';
import { Ad, AdItemIcon } from '@core/models';

import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AdsService {
	constructor(private device: DeviceDetectorService) {}

	private readonly _isdesktopContactInvisible$ = new BehaviorSubject<boolean>(false);
	private readonly _isTitleInvisible$ = new BehaviorSubject<boolean>(true);

	onWhatsappMessage(ad: Ad): void {
		const TARGET = this.device.isMobile() ? '_self' : '_blank';
		const PHONE_PREFIX = ad?.seller?.phoneCode;
		const PHONE_NUMBER = ad?.seller?.phone;

		//TODO: Update the template message later based on design once its ready
		const TEMPLATE_MESSAGE = encodeURIComponent(ad?.adlink);

		const URL = `${environment.WHATSAPP.sendMessage}${PHONE_PREFIX}${PHONE_NUMBER}&text=${TEMPLATE_MESSAGE}`;

		window.open(URL, TARGET);
	}

	onPhoneCall(ad): void {
		window.location.href = `tel: ${ad?.seller?.phoneCode}${ad.seller?.phone}`;
	}

	private readonly badgeMotors: AdItemIcon[] = [
		{ property: 'year', image: 'year.svg' },
		{ property: 'color', image: 'color.svg' },
		{ property: 'kilometer', image: 'kilometers.svg' },
		{ property: 'cylinderCount', image: 'engine.svg' },
	];

	private readonly badgeProperties: AdItemIcon[] = [
		{ property: 'bedroomCount', image: 'bedroom.svg' },
		{ property: 'bathroomCount', image: 'bathroom.svg' },
		{ property: 'sizeSquareMetres', image: 'size.svg' },
	];

	getBadgeProperties(section: string): AdItemIcon[] {
		return section === 'motors' ? this.badgeMotors.slice() : this.badgeProperties.slice();
	}

	isDesktopContactsInvisibile$(): Observable<boolean> {
		return this._isdesktopContactInvisible$.asObservable();
	}

	setIsDesktopContactsInvisibile$(isVisible: boolean): void {
		this._isdesktopContactInvisible$.next(isVisible);
	}

	isTitleInvisible$(): Observable<boolean> {
		return this._isTitleInvisible$.asObservable();
	}

	setIsTitleInvisible$(isVisible: boolean): void {
		this._isTitleInvisible$.next(isVisible);
	}
}
