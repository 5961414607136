import { Pipe, PipeTransform } from '@angular/core';
import { RouteData, RouteDataType, RouteService } from '@core/services/route.service';
import { CommonUtil } from '@core/utils/common.util';
import { Observable, map, skip } from 'rxjs';

/**
 * Show/hide the component
 *
 * Add a route data flag to show or hide a component.
 */

const DEFAULT_VISIBLE_COMPONENTS = [RouteDataType.IS_HEADER_VISIBLE, RouteDataType.IS_MOBILE_HEADER_VISIBLE, RouteDataType.IS_FOOTER_VISIBLE];

@Pipe({ name: 'visible' })
export class VisiblePipe implements PipeTransform {
	constructor(private readonly routeService: RouteService) {}

	transform(key: string): Observable<boolean> {
		return this.routeService.routeData$.pipe(
			skip(1),
			map((routeData: RouteData) => {
				if (CommonUtil.hasOwnProperty(routeData, key)) {
					return routeData[key];
				}

				/**
				 * Handle visible components as default.
				 *
				 * If key is not found, meaning by default the component should be visible.
				 */
				if (DEFAULT_VISIBLE_COMPONENTS.includes(key as RouteDataType)) {
					return true;
				}

				/**
				 * Handle hidden components as default.
				 *
				 * If key is not found, meaning by default the component should be hidden.
				 */
				return false;
			})
		);
	}
}
