import { Pipe, PipeTransform } from '@angular/core';
import { AdStatus } from '@core/enums/place-ad.enum';

@Pipe({
	name: 'adStatusFormat',
})
export class AdStatusFormatPipe implements PipeTransform {
	transform(value: string): string {
		if (value === AdStatus.SOLD) {
			value = 'Property Occupied';
		}

		return value
			.split('-')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
}
