import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { environment } from '@environments/environment';

/**
 * Fallback image error.
 *
 * Set a default image when the ngSrc value is failed to load.
 */

@Directive({
	selector: 'img[findqoAvatarImage]',
	host: {
		'(error)': 'updateUrl()',
	},
})
export class AvatarImagePreloadDirective {
	constructor(private readonly elementRef: ElementRef) {
		this._imageElement = <HTMLImageElement>this.elementRef.nativeElement;
	}

	private _default = environment.defaultAvatarImg;
	private _ngSrc: string | ArrayBuffer;
	private _imageElement: HTMLImageElement;

	@HostBinding('attr.ngSrc')
	@Input()
	set ngSrc(value: string | ArrayBuffer) {
		// HACK: This will update image src to the default image placeholder. `ngSrc` is not taking the new value on error.
		if (!value) {
			this._imageElement.src = this._default;
		} else {
			this._ngSrc = value;
		}
	}

	get ngSrc(): string | ArrayBuffer {
		return this._ngSrc || this._default;
	}

	updateUrl(): void {
		this._imageElement.src = this._default;
	}
}
