import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Ad } from '@core/models';
import { environment } from '@environments/environment';

/**
 * Create a route detail route
 */

@Pipe({
	name: 'adDetailRoute',
})
export class AdDetailRoutePipe implements PipeTransform {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	transform(ad: Ad): string[] {
		let origin = this.document.location.origin;

		// Remove the origin/domain from the URL as it will not work on the `routerLink`.
		let url = ad.adlink.replaceAll(origin, '');

		// If the running locally then replace the ad link from with the value of environment `appUrl`
		if (ad.adlink.indexOf(origin) <= 0) {
			url = ad.adlink.replaceAll(environment.appUrl, '');
		}

		return [...url.split('/')];
	}
}
