import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'standFormatPipe',
})
export class StandFormatPipe implements PipeTransform {
	transform(value: string, index: number, total: number, staticTranslation: string): string {
		return index === 0
			? value
			: index === total - 1 || index === 2
			? total <= 3
				? ' & ' + value.toLocaleLowerCase() + '.'
				: ', ' + value.toLocaleLowerCase() + ', ' + staticTranslation + '.'
			: ', ' + value.toLocaleLowerCase();
	}
}
