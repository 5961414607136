import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'adImagePipe',
})
export class AdImagePipe implements PipeTransform {
	constructor() {}

	transform(image: string | ArrayBuffer): string | ArrayBuffer {
		return image ? image : 'assets/images/ad-not-available.svg';
	}
}
