import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, PLATFORM_ID, Renderer2 } from '@angular/core';

import { ViewportService } from '@core/services/viewport.service';
import { AdsService } from '@shared/services/ads.service';

@Directive({
	selector: '[findqoAdElementVisible]',
})
export class AdElementVisibleDirective implements AfterViewInit, OnDestroy {
	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private adsService: AdsService,
		private viewport: ViewportService,
		@Inject(PLATFORM_ID) public platformId
	) {
		this.element = this.renderer.selectRootElement(this.elementRef.nativeElement, true);
	}

	checkVisibility: IntersectionObserver;

	element: Element;

	@Input('findqoAdElementVisible') elementName: string;

	//check if contacts visible in desktop view and ad summary header based on visibility status
	getContactsVisibilityStatus(): void {
		if (this.viewport.desktop) {
			this.checkVisibility = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							this.adsService.setIsDesktopContactsInvisibile$(true);
						} else {
							this.adsService.setIsDesktopContactsInvisibile$(false);
						}
					});
				},
				{ threshold: 0.2 }
			);

			this.checkVisibility.observe(this.element);
		}
	}

	//check if ad title visibile for viewport other than desktop and add price to ad contacts
	getAdTitleVisibilityStatus(): void {
		if (!this.viewport.desktop) {
			this.checkVisibility = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							this.adsService.setIsTitleInvisible$(true);
						} else {
							this.adsService.setIsTitleInvisible$(false);
						}
					});
				},
				{ threshold: 0.2 }
			);

			this.checkVisibility.observe(this.element);
		}
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			switch (this.elementName) {
				case 'contacts': {
					this.getContactsVisibilityStatus();
					break;
				}

				case 'title': {
					this.getAdTitleVisibilityStatus();
					break;
				}

				default:
			}
		}
	}

	ngOnDestroy(): void {
		if (this.checkVisibility) {
			this.checkVisibility.disconnect();
		}
	}
}
