import { Pipe, PipeTransform } from '@angular/core';
import { Photos } from '@core/models';

//to get the photo count order value
@Pipe({
	name: 'adPhotoIndex',
})
export class AdPhotoIndexPipe implements PipeTransform {
	constructor() {}

	transform(currentIndex: number, images: Photos[]): number {
		const FILTERED_IMAGES = images.slice(0, currentIndex + 1).filter((image) => image?.uploadFailed);

		return currentIndex - +FILTERED_IMAGES?.length + 1;
	}
}
