import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

/**
 * Fallback image error.
 *
 * Set a default image when the ngSrc value is failed to load.
 */

@Directive({
	selector: 'img[findqoAdImage]',
	host: {
		'(error)': 'updateUrl()',
		'(load)': 'load()',
	},
})
export class AdImagePreloadDirective {
	constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {
		this._imageElement = <HTMLImageElement>this.elementRef.nativeElement;
	}

	private _default = 'assets/images/ad-not-available.svg';
	private _ngSrc: string | ArrayBuffer;
	private _imageElement: HTMLImageElement;

	@HostBinding('attr.ngSrc')
	@Input()
	set ngSrc(value: string | ArrayBuffer) {
		// HACK: This will update image src to the default image placeholder. `ngSrc` is not taking the new value on error.
		if (!value) {
			this._imageElement.src = this._default;
		} else {
			this._ngSrc = value;
		}
	}

	get ngSrc(): string | ArrayBuffer {
		return this._ngSrc || this._default;
	}

	updateUrl(): void {
		this._imageElement.src = this._default;
	}

	load(): void {
		this.renderer.removeClass(this._imageElement, 'image-skeleton-loader');
	}
}
