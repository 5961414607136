import { Pipe, PipeTransform } from '@angular/core';
import { Photos } from '@core/models';

//to check if the photo is main photo or not
@Pipe({
	name: 'adMainPhoto',
})
export class AdMainPhotoPipe implements PipeTransform {
	constructor() {}

	transform(currentIndex: number, images: Photos[]): boolean {
		const FILTERED_IMAGES = images
			.map((image, index) => {
				image['index'] = index;

				return image;
			})
			.filter((image) => !image?.uploadFailed);

		return currentIndex === +FILTERED_IMAGES[0]?.index;
	}
}
