import { Pipe, PipeTransform } from '@angular/core';
import { CommonItem } from '@core/models';

@Pipe({
	name: 'tenantQuality',
})
export class TenantQualityPipe implements PipeTransform {
	transform(value: CommonItem[]): string {
		return value?.map((quality) => ' ' + quality?.name).toString();
	}
}
