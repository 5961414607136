import { Pipe, PipeTransform } from '@angular/core';
import { SectionType } from '@core/enums';

@Pipe({
	name: 'adDetailTitle',
})
export class AdDetailTitlePipe implements PipeTransform {
	constructor() {}

	transform(section: string = 'motors', aisle: string = 'cars') {
		if (section.toLowerCase() === SectionType.PROPERTIES_FOR_RENT || section.toLowerCase() === SectionType.PROPERTIES_FOR_SALE) {
			return 'n665';
		} else {
			return 'n282';
		}
	}
}
