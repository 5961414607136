import { Pipe, PipeTransform } from '@angular/core';
import { FilterTemplateType, FilterType } from '@core/enums';
import { Filter, FilterDropdownValue } from '@core/models/filters.model';
import { FILTERS_KEY_REFERENCED } from '@feature/general/filters/constants/filters.constants';

@Pipe({
	name: 'selectFilterDropdownMultiOption',
})
export class SelectFilterDropdownMultiOptionPipe implements PipeTransform {
	transform(filterValue: FilterDropdownValue, selectedOptionList: FilterDropdownValue[]): boolean {
		return selectedOptionList.some((filterDropdownValue: FilterDropdownValue) => filterDropdownValue.id === filterValue.id);
	}
}

@Pipe({
	name: 'selectFilterDropdownSingleOption',
})
export class SelectFilterDropdownSingleOptionPipe implements PipeTransform {
	transform(filterValue: FilterDropdownValue, selectedOption: string, filter: Filter): string {
		const REFERENCE_KEY = FILTERS_KEY_REFERENCED.includes(filter.paramName) ? 'reference' : 'id';

		return selectedOption === filterValue[REFERENCE_KEY] ? 'check' : '';
	}
}

@Pipe({
	name: 'selectAllFilterDropdownMultiOption',
})
export class SelectAllFilterDropdownMultiOptionPipe implements PipeTransform {
	transform(filterValueList: FilterDropdownValue[], selectedOptionList: FilterDropdownValue[]): boolean {
		const IS_ALL_SELECTED = filterValueList.every((filterValue: FilterDropdownValue) =>
			selectedOptionList.some((selected: FilterDropdownValue) => selected.id === filterValue.id)
		);

		return (!!filterValueList.length && IS_ALL_SELECTED) || !filterValueList.length;
	}
}

@Pipe({
	name: 'selectedFilterOption',
})
export class SelectedFilterOptionPipe implements PipeTransform {
	transform(filterValue: FilterDropdownValue, filter: Filter, selectedOption: FilterDropdownValue[] & string): boolean {
		switch (filter.templateType) {
			case FilterTemplateType.BOOLEAN_SELECTION:
			case FilterTemplateType.SINGLE_SELECTION:
				const REFERENCE_KEY = FILTERS_KEY_REFERENCED.includes(filter.paramName) ? 'reference' : 'id';

				return selectedOption === filterValue[REFERENCE_KEY];
			case FilterTemplateType.MULTI_SELECTION:
				return selectedOption?.some((filterDropdownValue: FilterDropdownValue) => filterDropdownValue.id === filterValue.id);
			default:
		}
		return false;
	}
}

@Pipe({
	name: 'priceSuffix',
})
export class PriceSuffixPipe implements PipeTransform {
	transform(input: string, type: FilterType): string | number {
		const PRICE = parseInt(input.replaceAll(',', ''), 10);

		/* 
			return the unmodified value, if
			 i. value is not number or 
			 ii. filter type is not price or 
			 iii. section is not property for sale	 
		*/
		if (Number.isNaN(PRICE) || type !== FilterType.PRICE) {
			return input;
		}

		const SUFFIXES = ['k', 'M'];

		if (PRICE < 1000) {
			return PRICE;
		}

		const EXP = Math.floor(Math.log(PRICE) / Math.log(1000));

		return PRICE / Math.pow(1000, EXP) + SUFFIXES[EXP - 1];
	}
}
