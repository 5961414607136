import { Pipe, PipeTransform } from '@angular/core';

//to dynamically update the array value based on viewport and number of columns
@Pipe({
	name: 'placeAdExtrasSlice',
})
export class PlaceADextrasSlicePipe implements PipeTransform {
	constructor() {}

	transform(value: any[], showMore = false, mobileViewPort = true): any[] {
		if (!mobileViewPort) {
			return value;
		} else {
			return value.slice(0, showMore ? value?.length : 4);
		}
	}
}
