import { Pipe, PipeTransform } from '@angular/core';
import { TenantDay, TenantTimeSlotRefercence } from '@core/enums';
import { CommonItem, TenantAvailibilySlots } from '@core/models';

@Pipe({
	name: 'tenantTimeSlots',
})
export class TenantTimeSlotsPipe implements PipeTransform {
	transform(value: CommonItem[], selectedDate: Date, availabilitySlots: TenantAvailibilySlots[]): CommonItem[] {
		if (availabilitySlots?.length > 0 && selectedDate) {
			let updatedSlots = [];
			const DAY = selectedDate.getDay();
			const TIME_SLOTS = availabilitySlots.filter((daySlots) => TenantDay[daySlots?.reference.toUpperCase()] === DAY)[0]?.timeSlots;

			TIME_SLOTS.forEach((slot) => {
				if (slot?.reference === TenantTimeSlotRefercence.MORNING) {
					updatedSlots.push(...value.slice(0, 6));
				}

				if (slot?.reference === TenantTimeSlotRefercence.MID_DAY) {
					updatedSlots.push(...value.slice(6, 12));
				}

				if (slot?.reference === TenantTimeSlotRefercence.AFTER_NOON) {
					updatedSlots.push(...value.slice(12, 18));
				}

				if (slot?.reference === TenantTimeSlotRefercence.EVENING) {
					updatedSlots.push(...value.slice(18));
				}
			});

			return updatedSlots;
		} else {
			return value;
		}
	}
}
