import { Pipe, PipeTransform } from '@angular/core';
import { ConfigState } from '@core/data-access/states/config.state';
import { FeatureVersion, FilterTemplateType, FilterType } from '@core/enums';
import { Filter, FilterDropdownValue } from '@core/models';
import { environment } from '@environments/environment';
import { FILTERS_KEY_REFERENCED } from '@feature/general/filters/constants/filters.constants';

interface OptionalArguments {
	isUpdating?: boolean;
	placeholder?: string;
}

@Pipe({
	name: 'filterLimitTo',
})
export class FilterLimitToPipe implements PipeTransform {
	constructor(private readonly configState: ConfigState) {}

	currentSelectedText = '';
	countryVersionNameMapper = {
		[FeatureVersion.V1]: this.configState.getTranslations()['n487'],
		[FeatureVersion.V2]: 'Ireland', // TODO: Ask for the translation
	};

	transform(filter: Filter, selectedOption: FilterDropdownValue[] & string, args?: OptionalArguments): string {
		const LIMIT = 20;

		if (args?.isUpdating) {
			return this.currentSelectedText;
		} else if (!selectedOption || !selectedOption.length) {
			const TRANSLATIONS = this.configState.getTranslations();
			this.currentSelectedText = args?.placeholder ? args.placeholder : '';

			if (filter.paramName === FilterType.COUNTY) {
				this.currentSelectedText = `${TRANSLATIONS['n658']} in ${this.countryVersionNameMapper[environment.featureVersion]}`;
			}

			return this.currentSelectedText;
		}

		let leadingText = '';
		let trailingText = '';

		switch (filter.templateType) {
			case FilterTemplateType.MULTI_SELECTION:
				let filterValueList = filter.values?.all || filter.values || [];

				filterValueList = filterValueList.filter((filterValue: FilterDropdownValue) =>
					selectedOption.some((selectedFilterValue: FilterDropdownValue) => filterValue.id === selectedFilterValue.id)
				);

				const FILTER_VALUE_NAME_LIST = filterValueList.map((filterValue: FilterDropdownValue) => filterValue.name);

				leadingText = FILTER_VALUE_NAME_LIST.splice(0, 2).join(', ');

				if (FILTER_VALUE_NAME_LIST?.length) {
					trailingText = `+${FILTER_VALUE_NAME_LIST.length} more`;
				}

				break;
			case FilterTemplateType.SINGLE_SELECTION:
			case FilterTemplateType.BOOLEAN_SELECTION:
				const FILTER_VALUE_LIST = filter.values?.all || filter.values;
				const FILTER_VALUE = FILTER_VALUE_LIST?.find((filterValue: FilterDropdownValue) => {
					const REFERENCE_KEY = FILTERS_KEY_REFERENCED.includes(filter.paramName) ? 'reference' : 'id';

					return filterValue[REFERENCE_KEY] === selectedOption;
				});

				// If the Filter value is undefined then just use the current selected value
				if (!FILTER_VALUE) {
					return this.currentSelectedText;
				}

				leadingText = FILTER_VALUE?.name;

				break;

			default:
		}

		leadingText = leadingText.length > LIMIT ? `${leadingText.substring(0, LIMIT)}...` : leadingText;

		this.currentSelectedText = `${leadingText} ${trailingText}`;

		return this.currentSelectedText;
	}
}
