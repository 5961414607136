import { Pipe, PipeTransform } from '@angular/core';
import { MY_ADS_HEADER_STATUS_MAPPING } from '@core/constants';
import { Ad } from '@core/models';

@Pipe({
	name: 'filterStatus',
})
export class FilterStatusPipe implements PipeTransform {
	constructor() {}

	transform(myAdList: Ad[], tabIndex: number): Ad[] {
		if (tabIndex === 0) {
			return myAdList;
		} else {
			return myAdList.filter((ad) => ad?.adStatus?.status === MY_ADS_HEADER_STATUS_MAPPING[tabIndex]);
		}
	}
}
