import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'expiringInDays',
})
export class ExpiringInDays implements PipeTransform {
	transform(date: string): number {
		const start = new Date().getTime();
		const end = new Date(date).getTime();

		const diffInMs = end - start;
		return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	}
}
