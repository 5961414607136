import { Pipe, PipeTransform } from '@angular/core';
import { ConfigState } from '@core/data-access/states/config.state';
import { Price, Translations } from '@core/models';

@Pipe({
	name: 'adPrice',
})
export class AdPricePipe implements PipeTransform {
	constructor(private configState: ConfigState) {}

	translations: Translations = this.configState.getTranslations();

	transform(price: Price, rentalPeriod?: string): string {
		if (price?.isPriceOnApplication === '1') {
			return this.translations['n3311'];
		} else {
			const VALUE = price?.maxValue
				? this.translations['n3423'] + ' ' + price?.symbol + price?.minValue + ' ' + this.translations['n3424'] + ' ' + price?.symbol + price?.maxValue
				: price?.symbol + price?.minValue;

			if (rentalPeriod) {
				return VALUE + ' ' + rentalPeriod;
			}

			return VALUE;
		}
	}
}
