import { isPlatformBrowser } from '@angular/common';
import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { debounceTime, Observable, Subscription } from 'rxjs';

/**
 * Observer for element to check if it's still visible in the viewport
 * It will check if the ancestor element is still intersecting to each other.
 *
 * @link https://blog.prototyp.digital/how-to-implement-intersection-observer-api-in-angular/
 */

@Directive({
	selector: '[findqoIntersectionObserver]',
	exportAs: 'intersection',
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
	constructor(@Inject(PLATFORM_ID) private readonly platformId: string, private element: ElementRef) {}

	@Input() root: HTMLElement | null = null;
	@Input() rootMargin = '0px 0px 0px 0px';
	@Input() threshold = 0;
	@Input() debounceTime = 0;
	@Input() isContinuous = true;

	@Output() isIntersecting = new EventEmitter<boolean>();

	// By default the elements is intersecting
	_isIntersecting = true;
	subscription: Subscription;

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.subscription = this.createAndObserve();
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	createAndObserve(): Subscription {
		const options: IntersectionObserverInit = {
			root: this.root,
			rootMargin: this.rootMargin,
			threshold: this.threshold,
		};

		return new Observable<boolean>((subscriber) => {
			const intersectionObserver = new IntersectionObserver((entries) => {
				const { isIntersecting } = entries[0];
				subscriber.next(isIntersecting);

				isIntersecting && !this.isContinuous && intersectionObserver.disconnect();
			}, options);

			intersectionObserver.observe(this.element.nativeElement);

			return {
				unsubscribe() {
					intersectionObserver.disconnect();
				},
			};
		})
			.pipe(debounceTime(this.debounceTime))
			.subscribe((status) => {
				this.isIntersecting.emit(status);
				this._isIntersecting = status;
			});
	}
}
