import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ViewportService {
	constructor() {}

	/*
		mobile = bootstrap: none
		tablet = bootstrap: (sm), md, lg
		desktop bootstrap: (xl), xxl

		reference: https://getbootstrap.com/docs/5.2/layout/breakpoints
	*/

	mobile: boolean;
	tablet: boolean;
	desktop: boolean;

	set(breakpoint: 'mobile' | 'tablet' | 'desktop') {
		if (breakpoint === 'mobile') {
			this.mobile = true;
			this.tablet = false;
			this.desktop = false;
		} else if (breakpoint === 'tablet') {
			this.mobile = false;
			this.tablet = true;
			this.desktop = false;
		} else if (breakpoint === 'desktop') {
			this.mobile = false;
			this.tablet = false;
			this.desktop = true;
		}
	}
}
