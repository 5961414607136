import { Pipe, PipeTransform } from '@angular/core';
import { ConfigState } from '@core/data-access/states/config.state';

/**
 * Easy getter on the template level for the Translations data
 */
@Pipe({ name: 'translations' })
export class TranslationsPipe implements PipeTransform {
	constructor(private readonly configState: ConfigState) {}

	transform(key: string): string {
		return this.configState.getTranslations()?.[key] || '';
	}
}
