import { Pipe, PipeTransform } from '@angular/core';
import { ErrorMessage, ErrorType } from '../../core/enums/form.enum';
import { ConfigState } from '@core/data-access/states/config.state';

@Pipe({
	name: 'formErrorMessage',
})
export class FormErrorMessagePipe implements PipeTransform {
	constructor(private configState: ConfigState) {}

	transform<T extends object>(errors: T): string {
		if (!errors) {
			return '';
		}

		return this.getErrorMessage(Object.keys(errors)[0]);
	}

	//here we can give predifened error messages or error message that we are getting with error key
	private getErrorMessage(type: string): string {
		const TRANSLATIONS = this.configState.getTranslations();

		let errorMessage = '';

		switch (type) {
			case ErrorType.EMAIL:
				errorMessage = TRANSLATIONS[ErrorMessage.EMAIL];

				break;
			case ErrorType.REQUIRED:
				errorMessage = TRANSLATIONS[ErrorMessage.REQUIRED];

				break;

			default:
				errorMessage = TRANSLATIONS[ErrorMessage.DEFAULT];
		}
		return errorMessage;
	}
}
