import { Pipe, PipeTransform } from '@angular/core';
import { SectionEnum } from '@core/enums/footer.enum';

@Pipe({
	name: 'countyLink',
})
export class CountyLinkPipe implements PipeTransform {
	transform(reference: string, sectionReference: string = 'sell-residential'): string {
		const AISLE_REFERENCE =
			sectionReference === SectionEnum.RENT_RESIDENTIAL || sectionReference === SectionEnum.SHARE_RESIDENTIAL ? 'properties-for-rent' : 'properties-for-sale';
		return `/${AISLE_REFERENCE}/${reference}/${sectionReference}`;
	}
}
