import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
	selector: '[iconLink]',
})
export class IconWithLinkDirective implements AfterViewInit {
	constructor(private el: ElementRef) {}

	ngAfterViewInit(): void {
		this.transformLinksToAnchor();
	}

	replaceNewLineWithTag(content: string): string {
		return content.replace(/\\n/g, '<br />');
	}

	private transformLinksToAnchor(): void {
		const regex = /(https?:\/\/[^\s]+)/g;
		const content = this.replaceNewLineWithTag(this.el.nativeElement.innerHTML);
		const replacedContent = content.replace(regex, (match) => this.generateLinkWithIcon(match));
		this.el.nativeElement.innerHTML = replacedContent;
	}

	private generateLinkWithIcon(url: string): string {
		return `<div class="d-inline-flex align-items-center"><span class="material-symbols-outlined ad-icon-link-wrapper me-1">public</span><a href="${url}" target="_blank">${url}</a></div>`;
	}
}
