import { Pipe, PipeTransform } from '@angular/core';
import { CommonItem } from '@core/models';

@Pipe({
	name: 'tenantSummaryTimeSlot',
})
export class TenantSummaryTimeSlotPipe implements PipeTransform {
	transform(value: CommonItem[], selectedTimeSlotId: string): string {
		return value.filter((timeslot) => timeslot?.id === selectedTimeSlotId)[0]?.name;
	}
}
