import { Pipe, PipeTransform } from '@angular/core';
import { ConfigState } from '@core/data-access/states/config.state';
import { BooleanStatus } from '@core/enums';
import { Tenant } from '@core/models';

@Pipe({
	name: 'tenantOtherInfo',
})
export class TenantOtherInfoPipe implements PipeTransform {
	constructor(private readonly configState: ConfigState) {}

	transform(value: Tenant): string {
		let text =
			value?.isMarried === BooleanStatus?.TRUE ? this.configState.getTranslations()?.['n3678'] + ', ' : this.configState.getTranslations()?.['n3679'] + ', ';

		text =
			text +
			(value?.isKids === BooleanStatus?.FALSE || !value?.isKids
				? '0 ' + this.configState.getTranslations()?.['n3684'] + ', '
				: value?.isKids === BooleanStatus?.TRUE
				? value?.isKids + ' ' + this.configState.getTranslations()?.['n3684'] + ', '
				: value?.isKids + ' ' + this.configState.getTranslations()?.['n3685'] + ', ');

		text =
			text +
			(value?.isSmoking === BooleanStatus?.TRUE
				? this.configState.getTranslations()?.['n3681'] + ', ' || ''
				: this.configState.getTranslations()?.['n3680'] + ', ' || '');
		text =
			text +
			(value?.isPets === BooleanStatus?.TRUE ? this.configState.getTranslations()?.['n3683'] || '' : this.configState.getTranslations()?.['n3682'] || '');

		return text;
	}
}
