import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toLocalUpperCase',
})
export class ToLocaleUpperCase implements PipeTransform {
	transform(value: string): string {
		return value?.toLocaleUpperCase();
	}
}
