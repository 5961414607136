import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'aisleFormatPipe',
})
export class AisleFormatPipe implements PipeTransform {
	transform(value: string, index, total): string {
		return index === 0 ? value : index < total - 1 ? ', ' + value.toLocaleLowerCase() : ' & ' + value.toLocaleLowerCase();
	}
}
