import { Pipe, PipeTransform } from '@angular/core';

//to dynamically update the array value based on viewport and number of columns
@Pipe({
	name: 'adDetailsSlice',
})
export class AdDetailsSlicePipe implements PipeTransform {
	constructor() {}

	transform(value: any[], showMore = false, i = 0, mobileViewPort = true, isExtras = false): any[] {
		const LENGTH = value.length;

		if (mobileViewPort) {
			if (i === 0) {
				if (showMore) {
					return value.slice(0);
				}

				return value.slice(0, 5);
			}

			return null;
		} else {
			if (isExtras) {
				if (LENGTH >= 15) {
					const PER_COLUMN = Math.round(LENGTH / 3);
					return value.slice(PER_COLUMN * i, PER_COLUMN * (i + 1));
				} else {
					return value.slice(5 * i, 5 * (i + 1));
				}
			}

			const PER_COLUMN = Math.round(LENGTH / 2);
			return value.slice(PER_COLUMN * i, PER_COLUMN * (i + 1));
		}
	}
}
