import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureVersion } from '@core/enums';
import { environment } from '@environments/environment';

/**
 * Dynamically display the component based on the current feature version.
 */

@Directive({
	selector: '[findqoFeatureIf]',
})
export class FeatureIfDirective implements OnInit {
	constructor(private readonly templateRef: TemplateRef<Element>, private readonly viewContainerRef: ViewContainerRef) {}

	@Input() findqoFeatureIf: FeatureVersion[];

	ngOnInit(): void {
		if (this.findqoFeatureIf.includes(environment.featureVersion)) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}
}
