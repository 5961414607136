import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Aisle, Section } from '@core/models';
import { Observable, map } from 'rxjs';

@Pipe({
	name: 'filtersAisle',
})
export class FiltersAislePipe implements PipeTransform {
	constructor(private readonly route: ActivatedRoute) {}

	transform(sectionList: Section[]): Observable<Aisle[]> {
		return this.route.params.pipe(
			map((params: Params) => {
				return sectionList.find((section: Section) => section.reference === params['section'])?.aisles || [];
			})
		);
	}
}
