import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments/environment';

interface TranslationsMapper {
	[key: string]: {
		links: {
			[key: string]: string;
		};
		domain: string;
		target: string;
		linksList: string[];
	};
}

@Pipe({
	name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
	/**
	 * Specifies the text that needs to convert to <a> tag.
	 */
	translationsMapper: TranslationsMapper = {
		n3266: {
			links: {
				'Anthony Mc Cann': 'anthony-mc-cann-593555207/',
				'Twana Daniel': 'twana-daniel/',
				'Zana Aziz': 'zanaaziz/',
			},
			domain: 'https://www.linkedin.com/in',
			target: '_blank',
			linksList: ['Anthony Mc Cann', 'Twana Daniel', 'Zana Aziz'],
		},
		n3663: {
			links: {
				'Tenantin.ie*': '',
			},
			domain: 'https://www.tenantin.ie/',
			target: '_blank',

			linksList: ['Tenantin.ie\\*'],
		},

		n3701: {
			links: {
				here: '',
			},
			domain: environment.appUrl,
			target: '',
			linksList: ['here'],
		},
	};

	/**
	 * Find links in plain-text and convert them to HTML <a> tags.
	 *
	 * @param value Translation text
	 * @param args { translation: `translationKey`, target: `specifies where to open the linked document` }
	 *
	 * @returns { string } Converted text with <a> tags
	 */
	transform(value: string, args?: { translation?: string; target?: '_blank' }): string {
		/**
		 * Check if the translation key has a value, then use the translation mapper
		 * to specify the texts that need to convert to <a> tag.
		 */
		if (args && args?.translation) {
			const LINK = this.translationsMapper[args.translation];
			const LINK_REGEX = new RegExp(LINK.linksList.join('|'), 'g');
			return value.replace(LINK_REGEX, (match: string) => `<a href="${LINK.domain}/${LINK.links[match]}" target="${LINK.target}" >${match}</a>`);
		}

		// Find a link then convert it to <a> tag.
		return value.replace(/https?:\/\/\S+/g, (match: string) => `<a href="${match}" target="${args?.target || '_blank'}">${match}</a>`);
	}
}
