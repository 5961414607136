import { Pipe, PipeTransform } from '@angular/core';
import { TIME_INTERVALS } from '@core/constants/meeting-days.constant';
import { TimeSlotFieldKeys } from '@core/enums';
import { TimeSlots, ZoneData } from '@core/models/meeting-schedule.model';

@Pipe({
	name: 'timeSlotName',
})
export class TimeSlotsNamePipe implements PipeTransform {
	transform(slotId: string, timeSlots: TimeSlots[]): string {
		const SLOT = timeSlots.find((slt) => slt.id === slotId);
		return SLOT?.name;
	}
}

@Pipe({
	name: 'timeZoneName',
})
export class TimeZoneNamePipe implements PipeTransform {
	transform(zoneId: string, zoneData: ZoneData[]): string {
		const ALL_ZONE = zoneData?.reduce((acc, curr) => {
			return [...acc, ...curr.timeZones];
		}, []);

		const TIME_ZONE: any = ALL_ZONE?.find((zone) => zone.id === zoneId);

		const CURRENT_TIME = new Intl.DateTimeFormat('en-US', {
			timeZone: TIME_ZONE?.iana,
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		}).format(new Date());

		return TIME_ZONE?.name + ` (${CURRENT_TIME})`;
	}
}

@Pipe({
	name: 'timeZoneTime',
})
export class TimeZoneTimePipe implements PipeTransform {
	transform(timeZone: string): string {
		try {
			const CURRENT_TIME = new Intl.DateTimeFormat('en-US', {
				timeZone,
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			}).format(new Date());

			return CURRENT_TIME;
		} catch {
			// Fallback to using UTC time when timezone data is unavailable.
			return '';
		}
	}
}

@Pipe({
	name: 'timeSlots',
})
export class TimeSlotsPipe implements PipeTransform {
	transform(timeSlots: TimeSlots[], fieldKey: string): TimeSlots[] {
		let timeSlotsData = [...timeSlots];

		// Don't show last option in start time slots list
		if (fieldKey === TimeSlotFieldKeys.START_TIME) {
			timeSlotsData.pop();
		}
		// Don't show first option in last time slots list
		else {
			timeSlotsData = timeSlotsData.slice(1);
		}

		return timeSlotsData;
	}
}

@Pipe({
	name: 'timeSlotName',
})
export class TimeSlotNamePipe implements PipeTransform {
	getNextTime(startTime, interval) {
		// Create a Date object with the start time
		let timeParts = startTime.split(':');
		let date = new Date();
		date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0); // Hours, minutes, seconds, milliseconds

		// Add the interval (in minutes)
		date.setMinutes(date.getMinutes() + +interval);

		// Format the time to HH:MM format
		let hours = date.getHours();
		let minutes: string | number = date.getMinutes();

		// Pad single digit minutes with leading zero
		minutes = `${minutes < 10 ? '0' + minutes : minutes}`;

		// Format as 'HH:MM'
		return `${hours}:${minutes}`;
	}

	transform(startTime: string, interval: string): string {
		return `${startTime}-${this.getNextTime(startTime, interval)}`;
	}
}

@Pipe({
	name: 'timeIntervalName',
})
export class TimeIntervalNamePipe implements PipeTransform {
	readonly intervals = TIME_INTERVALS;

	transform(value: string): string {
		return this.intervals?.find((it) => it.value === value)?.name;
	}
}
